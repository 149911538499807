import React from "react";

import "./styles.scss";
import ReferralInfo from "../referralInfo";
import { ReservedFiltersEnum } from "../types";

import BannerBlock from "./BannerBlock";
import ExpertList from "./expertList";
// import TopPanel from "./topPanel";
import { ListingProps } from "./types";

const Listing = (props: ListingProps) => {
  const {
    selectedFilters,
    experts,
    isLoading,
    isMoreLoading,
    nightBannerData,
    onChange,
    onLoadMoreClick,
  } = props;

  return (
    <div className="listing">
      <BannerBlock nightBannerData={nightBannerData} />
      <ReferralInfo />
      {/* <TopPanel selectedFilters={selectedFilters} onChange={onChange} /> */}
      <ExpertList
        experts={experts}
        isLoading={isLoading}
        isMoreLoading={isMoreLoading}
        onChange={onChange}
        onLoadMoreClick={onLoadMoreClick}
        // todo: made correct sorting on backand and delete this line
        sorting={selectedFilters[ReservedFiltersEnum.Sorting]?.[0] ?? 1}
      />
    </div>
  );
};

export default Listing;
