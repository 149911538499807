import React from "react";

import Sign2xPath from "@images/logo/sign.2x.png";
import Sign3xPath from "@images/logo/sign.3x.png";
import SignPath from "@images/logo/sign.png";

import { Icon, IconSizeEnum } from "@/components/common/icon";
import { isBrowser } from "@/utils/env";
import { cleanupFromDangerousTags } from "@/utils/stringUtils";

import { socialLinks } from "../constants";

import { NightBannerType } from "./types";

import "../styles.scss";

const NightBanner = ({ data }: NightBannerType) => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <div className="banner-block">
      <img
        src={SignPath}
        srcSet={`${SignPath}, ${Sign2xPath} 2x, ${Sign3xPath} 3x`}
        alt="logo"
        className="banner-block__sign"
      />
      {data.commonNightBannerTitle
        && (
        <div
          className="banner-block__header"
          dangerouslySetInnerHTML={{
            __html: cleanupFromDangerousTags(data.commonNightBannerTitle),
          }}
        />
        )}
      {data.commonNightBannerDescription && (
        <div
          className="banner-block__text"
          dangerouslySetInnerHTML={{
            __html: cleanupFromDangerousTags(data.commonNightBannerDescription),
          }}
        />
      )}
      <div className="banner-block__socials">
        {socialLinks.map((social) =>
          (
            <a
              className={`banner-block__social-item banner-block__social-item--${social.title.toLowerCase()}`}
              href={social.link}
              key={social.title}
              target="_blank"
              rel="noreferrer"
              role="link"
              aria-label={`Link to ${social.title}`}
            >
              <Icon type={social.title} size={IconSizeEnum.Size48} />
            </a>
          ))}
      </div>
    </div>
  );
};

export default NightBanner;
